import React, { useState } from "react";
import { Button, Col, Icon, message, Popconfirm } from "antd";
import {
    adminResponseRequest,
    disburseLoan,
} from "../../../APIsHandler/ApiController";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";

export default function LoanRequestDetailsAction(props) {
    const { loanRequest, loanRequestAmount } = props;

    const [declineRequest, setDeclineRequest] = useState(false);
    const [addRequest, setAddRequest] = useState(false);
    const [disburse, setDisburse] = useState(false);

    const declineLoan = () => {
        setDeclineRequest(true);
        adminResponseRequest(
            {
                loan_request_id: loanRequest.id,
                status: "declined",
                comment: "Declined",
            },
            onDeclineDatails
        );
    };

    const acceptRequest = () => {
        setAddRequest(true);
        adminResponseRequest(
            {
                loan_request_id: loanRequest.id,
                status: "approved",
                comment: "Approved",
            },
            onAddDatails
        );
    };

    const transferFund = () => {
        setDisburse(true);

        const params = {
            loan_request_id: loanRequest.id,
            status: "success",
            type: "credit",
            description: `Credit wallet for ${
                loanRequest.loan_package && loanRequest.loan_package.name
            } loan of ${naira} ${formatCurrency(loanRequestAmount)}.`,
        };
        disburseLoan(params, onDisburse);
    };

    const onDeclineDatails = (status, data) => {
        if (status) {
            setDeclineRequest(false);
            message.success("The request has been declined!");
            window.location.reload();
        } else {
            message.error("Unable to decline request! " + data.data.message);
            setDeclineRequest(false);
        }
    };

    const onAddDatails = (status, data) => {
        if (status) {
            setAddRequest(false);
            message.success("The request has been accepted!");
            window.location.reload();
        } else {
            message.error("Unable to accept request! " + data.data.message);
            setAddRequest(false);
        }
    };

    const onDisburse = (status, data) => {
        if (status) {
            setDisburse(false);
            message.success("Money has been disbursed into user wallet!", 5);
            window.location.reload();
        } else {
            message.error("Unable to disburse fund.");
        }
    };

    switch (loanRequest.status) {
        case "pending":
            return (
                <>
                    <Col span={12} className="z-flex-end">
                        <Popconfirm
                            title="Are you sure you want to decline the loan request?"
                            onConfirm={declineLoan}
                            onCancel={() => {}}
                            okText="Decline"
                            cancelText="No"
                            key="1"
                        >
                            <Button
                                className="z-landing-btn z-section-btn z-profile-button z-flex-row"
                                shape="round"
                                size="large"
                                key="3"
                                loading={declineRequest}
                            >
                                Decline
                                <Icon type="close" />
                            </Button>
                        </Popconfirm>
                    </Col>
                    <Col span={12}>
                        <Popconfirm
                            title="Are you sure you want to accept the loan request?"
                            onConfirm={acceptRequest}
                            onCancel={() => {}}
                            okText="Accept"
                            cancelText="No"
                            key="20"
                        >
                            <Button
                                className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                                shape="round"
                                size="large"
                                key="23"
                                loading={addRequest}
                            >
                                Accept
                                <Icon type="check" />
                            </Button>{" "}
                        </Popconfirm>
                    </Col>
                </>
            );

        case "declined":
            return (
                <Col span={24} className="z-flex-center">
                    <p className="z-declined">Declined</p>
                </Col>
            );

        case "cancelled":
            return (
                <Col span={24} className="z-flex-center">
                    <p className="z-accepted">Cancelled</p>
                </Col>
            );

        default:
            return loanRequest.loan_disbursed === 0 ? (
                <Col span={24} className="z-flex-center">
                    <Popconfirm
                        title="Are you sure you want to disburse into the user wallet?"
                        onConfirm={transferFund}
                        onCancel={() => {}}
                        okText="Disburse"
                        cancelText="No"
                        key="22"
                    >
                        <Button
                            className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                            shape="round"
                            size="large"
                            key="21"
                            loading={disburse}
                        >
                            Disburse Loan
                            <Icon type="check" />
                        </Button>{" "}
                    </Popconfirm>
                </Col>
            ) : (
                <Col span={24} className="z-flex-center">
                    <p className="z-accepted">Accepted and Disbursed</p>
                </Col>
            );
    }
}
