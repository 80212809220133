import React, { useEffect, useState } from "react";
import { getDirectDebitTransactionList } from "../../../APIsHandler/ApiController";
import { Col, message, Row, Skeleton, Table } from "antd";
import overviewIcon from "../../../assets/images/dashboard/overview.svg";
import { useBanks } from "../../../hooks/useBanks";
import Column from "antd/lib/table/Column";
import moment from "moment";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";
import NavigationTab from "../../NavigationTab";

export default function DirectDebitTransaction() {
    const { getBank } = useBanks();

    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [noOfPages, setNoOfPages] = useState(5);

    useEffect(() => {
        fetchTransactions(page, pageSize);
    }, []);

    const fetchTransactions = (page_number = 1, page_size = 10) => {
        setLoading(true);

        getDirectDebitTransactionList({
            page_number,
            page_size,
        })
            .then((res) => {
                setTransactions(res.data.data);
            })
            .catch((err) => {
                message.error(
                    "Unable to retrieve transactions at the moment.",
                    3
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const navigateLeft = () => {
        if (page !== 1) {
            const newPage = page - 1;

            setPage(newPage);
            fetchTransactions(newPage, pageSize);
        }
    }
    
    const navigateRight = () => {
        if (page !== noOfPages) {

            const newPage = page + 1;
            setPage(newPage);
            fetchTransactions(newPage, pageSize);
        }
    }

    return (
        <Skeleton loading={loading} active>
            <Row>
                <Col span={24}>
                    <div
                        className="z-dashboard-panel z-mb-5 z-width-100 z-admin-panel2"
                        style={{ height: "100%" }}
                    >
                        <div className="z-mb-6">
                            <h1 className="z-dashboard-label">
                                Bank Transactions
                            </h1>
                            <div className="z-flex-start z-fill-content mb-3">
                                <img
                                    src={overviewIcon}
                                    className="img-fluid mr-3"
                                    alt="Overview Icon"
                                />
                                <span className="z-label-text">
                                    All Transactions
                                </span>
                            </div>
                        </div>
                        <Table
                            // rowSelection={[]}
                            bordered={false}
                            dataSource={transactions}
                            className="z-admin-table z-todo"
                            pagination={false}
                            rowKey="mandateReferenceNumber"
                            rowClassName={(record, index) => {
                                return "z-stripped z-table-border";
                            }}
                            scroll={{ x: "220%" }}
                        >
                            <Column
                                title="Amount"
                                dataIndex="amount"
                                key="amount"
                                render={(text, record) => {
                                    return (
                                        <p className="z-table-com z-font-14 z-font-bold">
                                            {naira}{" "}
                                            {formatCurrency(
                                                parseFloat(record.amount)
                                            )}
                                        </p>
                                    );
                                }}
                            />
                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                                render={(text, record) => {
                                    return (
                                        <p className="z-table-com">
                                            {record.status}
                                        </p>
                                    );
                                }}
                            />
                            <Column
                                title="Originator Account Name"
                                dataIndex="originatorAccountName"
                                key="originatorAccountName"
                                render={(text, record) => {
                                    return (
                                        <p className="z-table-com">
                                            {record.originatorAccountName}
                                        </p>
                                    );
                                }}
                            />
                            <Column
                                title="Originator Account Number"
                                dataIndex="originatorAccountNumber"
                                key="originatorAccountNumber"
                                render={(text, record) => {
                                    return (
                                        <p className="z-table-com">
                                            {record.originatorAccountNumber}
                                        </p>
                                    );
                                }}
                            />
                            <Column
                                title="Originator BVN"
                                dataIndex="originatorBankVerificationNumber"
                                key="originatorBankVerificationNumber"
                                render={(text, record) => {
                                    return (
                                        <p className="z-table-com">
                                            {
                                                record.originatorBankVerificationNumber
                                            }
                                        </p>
                                    );
                                }}
                            />
                            <Column
                                title="Source Institution"
                                dataIndex="sourceInstitutionCode"
                                key="sourceInstitutionCode"
                                render={(text, record) => {
                                    return (
                                        <p className="z-table-com">
                                            {getBank(
                                                record.sourceInstitutionCode
                                            )}
                                        </p>
                                    );
                                }}
                            />
                            <Column
                                title="Beneficiary Account Name"
                                dataIndex="beneficiaryAccountName"
                                key="beneficiaryAccountName"
                                render={(text, record) => {
                                    return (
                                        <p className="z-table-com">
                                            {record.beneficiaryAccountName}
                                        </p>
                                    );
                                }}
                            />
                            <Column
                                title="Beneficiary Account Number"
                                dataIndex="beneficiaryAccountNumber"
                                key="beneficiaryAccountNumber"
                                render={(text, record) => {
                                    return (
                                        <p className="z-table-com">
                                            {record.beneficiaryAccountNumber}
                                        </p>
                                    );
                                }}
                            />
                            <Column
                                title="Beneficiary BVN"
                                dataIndex="beneficiaryBankVerificationNumber"
                                key="beneficiaryBankVerificationNumber"
                                render={(text, record) => {
                                    return (
                                        <p className="z-table-com">
                                            {
                                                record.beneficiaryBankVerificationNumber
                                            }
                                        </p>
                                    );
                                }}
                            />
                            <Column
                                title="Destination Institution"
                                dataIndex="destinationInstitutionCode"
                                key="destinationInstitutionCode"
                                render={(text, record) => {
                                    return (
                                        <p className="z-table-com">
                                            {getBank(
                                                record.destinationInstitutionCode
                                            )}
                                        </p>
                                    );
                                }}
                            />
                            <Column
                                title="Date created"
                                dataIndex="created"
                                key="created"
                                render={(text, record) => {
                                    return (
                                        <p className="z-table-com">
                                            {moment(record.callTime).format(
                                                "DD-MM-YYYY"
                                            )}
                                        </p>
                                    );
                                }}
                            />
                        </Table>
                        {transactions.length > 0 && (
                            <div className="z-admin-gr-div">
                                <NavigationTab
                                    currentPage={page}
                                    noOfPages={noOfPages}
                                    navigateLeft={navigateLeft}
                                    navigateRight={navigateRight}
                                />
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </Skeleton>
    );
}
