import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { notification, Row, Col, Skeleton } from "antd";
import { useHistory } from "react-router-dom";
import Card from "./Card";
import Bank from "./Bank/index";
import {
    isBvnVerified,
} from "../../../APIsHandler/ApiController";

const openNotification = (type, title, description) => {
    notification[type]({
        message: `${title}`,
        description: `${description}`,
    });
};

function Repayment({ user }) {
    const history = useHistory();

    useEffect(() => {
        const param = new URLSearchParams({
            redirect: "apply",
        });

        const bvn =
            user.data.hasOwnProperty("user_profile") &&
            user.data.user_profile !== null
                ? user.data.user_profile.bvn
                : null;

        if (!bvn) {
            openNotification(
                "error",
                "Incomplete KYC",
                "Please, complete KYC to continue"
            );

            setTimeout(() => {
                history.push(`/dashboard/settings/?${param.toString()}`);
            }, 1000);

            return;
        }

        isBvnVerified(bvn)
            .then((res) => {
                if (!res.data.data.is_verified) {
                    openNotification(
                        "error",
                        "Incomplete KYC",
                        "Please, complete KYC to continue"
                    )
                    history.push(`/dashboard/settings/?${param.toString()}`);
                }
            })
            .catch((error) => {
                openNotification(
                    "error",
                    "Error",
                    error.data.message || "Unable to get BVN status"
                );
                history.push(`/dashboard/settings/?${param.toString()}`);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const [paymentOption, setPaymentOption] = useState("bank_direct_debit");
    const [loading, setLoading] = useState(true);

    return (
        <Skeleton loading={loading} active>
            <Row>
                <Col span={12} className="z-res-12">
                    <div className="z-height-100_ z-dash-p z-apply-shadow z-dash-apply">
                        <h1 className="z-dashboard-label">Repayment Options</h1>
                        <Row>
                            <Col>
                                <div className="z-flex-space z-guarantor-padding2 z-mb-3">
                                    <div
                                        className={
                                            paymentOption ===
                                            "bank_direct_debit"
                                                ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                                : "z-cursor-poiner z-width-100 z-flex-row"
                                        }
                                        onClick={() =>
                                            setPaymentOption(
                                                "bank_direct_debit"
                                            )
                                        }
                                    >
                                        <p className="z-guarantor-tab z-ml-8 z-notification-active">
                                            Bank Account
                                        </p>
                                    </div>
                                    <div
                                        className={
                                            paymentOption === "card"
                                                ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                                : "z-cursor-poiner z-width-100 z-flex-row"
                                        }
                                        onClick={() => setPaymentOption("card")}
                                    >
                                        <p className="z-guarantor-tab z-ml-8 z-notification-active">
                                            Debit Card
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {paymentOption === "bank_direct_debit" ? (
                            <Bank />
                        ) : (
                            <Card />
                        )}
                    </div>
                </Col>
            </Row>
        </Skeleton>
    );
}

/**
 * function to map the state to props
 * */
function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Repayment);
