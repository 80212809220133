import React from "react";
import { Modal, Button, Row, Col, message } from "antd";
import { useHistory } from "react-router-dom";

export default function AuthorizationInformation({
  showModal,
  closeModal,
  createDirectDebitResponse,
  hideProceedBtn,
}) {
  const history = useHistory();

  function handleCompleteAuthorization() {
    closeModal();
    const param = new URLSearchParams({
      repayment_option: "bank",
      id: createDirectDebitResponse.id,
    });

    message.success("Bank added successfully", 3);

    hideProceedBtn
      ? history.go(0)
      : history.push(`/dashboard/apply/?${param.toString()}`);
  }

  return (
    <Modal
      className="z-custom z-custom2 z-custom3 z-app-modal"
      visible={showModal}
      title={"_"}
      onCancel={closeModal}
      closable={false}
      maskClosable={false}
      footer={[
        <Button
          className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row z-btn-company z-border-active"
          shape="round"
          size="large"
          onClick={handleCompleteAuthorization}
          key={1}
        >
          Done
        </Button>,
      ]}
    >
      <div>
        <div className="z-flex-space z-modal-label-div">
          <div>
            <p className="z-loan-header">Authorization Information</p>
          </div>
        </div>

        <Row className="z-flex-space">
          <Col span={24} className="z-mandate-response">
            {createDirectDebitResponse &&
              createDirectDebitResponse.responseDescription}
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
