import React, { useEffect, useState } from "react";
import { Col, Icon, Row } from "antd";
import { REPAYMENT_OPTIONS } from "../../../constants";
import moment from "moment";
import DefaultAvatar from "../../../assets/images/unisex_avatar_icon.png";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { getLoanRequestById } from "../../../APIsHandler/ApiController";
import LoanRequestDetailsAction from "./LoanRequestDetailsAction";
import { useBanks } from "../../../hooks/useBanks";

const MODAL_TABS = ["user", "user_bvn", "guarantor"];
const TAB_TITLE_MAP = {
    user: "Applicant Profile",
    user_bvn: "Applicant BVN",
    guarantor: "Guarantor Profile",
};

export default function LoanRequestDetails() {
    const { loanRequestId } = useParams();

    const { getBank } = useBanks();

    const [loanRequest, setLoanRequest] = useState(null);
    const [loanPackage, setLoanPackage] = useState(null);
    // const [loanPackageName, setLoanPackageName] = useState(null);
    const [loanRequestAmount, setLoanRequestAmount] = useState(null);
    const [tab, setTab] = useState("user");
    const [user, setUser] = useState(null);
    const [company, setCompany] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [userProfileAvatar, setUserProfileAvatar] = useState(null);
    const [requestGuarantor, setRequestGuarantor] = useState(null);
    const [requestGuarantorUser, setRequestGuarantorUser] = useState(null);
    const [requestGuarantorProfile, setRequestGuarantorProfile] =
        useState(null);
    const [requestGuarantorProfileAvatar, setRequestGuarantorProfileAvatar] =
        useState(null);
    const [repaymentOptionStatus, setRepaymentOptionStatus] = useState(null);
    const [identityVerification, setIdentityVerification] = useState(null);
    const [mandate, setMandate] = useState(null);

    useEffect(() => {
        if (loanRequestId) {
            getLoanRequestById(loanRequestId)
                .then((res) => {
                    console.log(res.data);

                    setLoanRequest(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);

    useEffect(() => {
        if (loanRequest) {
            setLoanPackage(loanRequest.loan_package);
            setLoanRequestAmount(loanRequest.amount || loanPackage.amount);
            // setLoanPackageName(loanRequest.loan_package.name);
            setUser(loanRequest.user);
            setCompany(loanRequest.user ? loanRequest.user.company : null);
            setRequestGuarantor(loanRequest.request_guarantor);
            setRepaymentOptionStatus(
                loanRequest.repayment_option === REPAYMENT_OPTIONS.BANK
                    ? loanRequest.mandate.status
                    : "Approved"
            );

            setMandate(
                loanRequest.repayment_option === REPAYMENT_OPTIONS.BANK
                    ? loanRequest.mandate
                    : null
            );
        }
    }, [loanRequest]);

    useEffect(() => {
        if (user) {
            setIdentityVerification(user.identity_verification_log);
            setUserProfile(user.user_profile);
        }
    }, [user]);

    useEffect(() => {
        if (userProfile) {
            setUserProfileAvatar(userProfile.avatar);
        }
    }, [userProfile]);

    useEffect(() => {
        if (requestGuarantor) {
            setRequestGuarantorUser(requestGuarantor.user);
        }
    }, [requestGuarantor]);

    useEffect(() => {
        if (requestGuarantorUser) {
            setRequestGuarantorProfile(requestGuarantorUser.user_profile);
        }
    }, [requestGuarantorUser]);

    useEffect(() => {
        if (requestGuarantorProfile) {
            setRequestGuarantorProfileAvatar(requestGuarantorProfile.avatar);
        }
    }, [requestGuarantorProfile]);

    const moveTabLeft = () => {
        const i = MODAL_TABS.indexOf(tab);

        if (i === 0) {
            setTab(MODAL_TABS[MODAL_TABS.length - 1]);
        } else {
            setTab(MODAL_TABS[i - 1]);
        }
    };

    const moveTabRight = () => {
        const i = MODAL_TABS.indexOf(tab);

        if (i === MODAL_TABS.length - 1) {
            setTab(MODAL_TABS[0]);
        } else {
            setTab(MODAL_TABS[i + 1]);
        }
    };

    return (
        <>
            <Row>
                <Col span={24}>
                    <div className="z-flex-space">
                        <div
                            className={
                                "z-not-left  z-flex-column-center z-modal-b z-modal-buser"
                            }
                            onClick={moveTabLeft}
                        >
                            <Icon type="left" />
                        </div>
                        <div className="z-width-35 z-nav-holder-p loan-request-details-title">
                            {TAB_TITLE_MAP[tab]}
                        </div>
                        <div
                            className={
                                "z-not-left z-flex-column-center z-modal-b z-modal-buser"
                            }
                            onClick={moveTabRight}
                        >
                            <Icon type="right" />
                        </div>
                    </div>
                </Col>
            </Row>
            {/* Buttons */}
            <Row className="z-mt-3 mb-0">
                {loanRequest && (
                    <LoanRequestDetailsAction
                        loanRequest={loanRequest}
                        loanRequestAmount={loanRequestAmount}
                    />
                )}
            </Row>
            {/* Avatar */}
            <Row>
                <div className="z-flex-space">
                    <div className="z-testimonial-avatar z-profile-avatar z-mb-1 z-img-100">
                        {tab === "user" && (
                            <img
                                src={
                                    userProfileAvatar
                                        ? userProfileAvatar
                                        : DefaultAvatar
                                }
                                alt="avatar"
                            />
                        )}
                        {tab === "user_bvn" && identityVerification ? (
                            <img
                                src={
                                    identityVerification.photo
                                        ? identityVerification.photo
                                        : DefaultAvatar
                                }
                                alt="avatar"
                            />
                        ) : (
                            <></>
                        )}
                        {tab === "guarantor" && requestGuarantor ? (
                            <img
                                src={
                                    requestGuarantorProfileAvatar
                                        ? requestGuarantorProfileAvatar
                                        : DefaultAvatar
                                }
                                alt="avatar"
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                    {/* Names */}
                    <div className="z-width-64">
                        {tab === "user" && (
                            <div className="z-width-100 z-ml-2 z-mt-8">
                                <div className="z-flex-start">
                                    <div className="loan-request-details-left">
                                        <p>First Name</p>
                                        <p>Last Name</p>
                                        <p>Email</p>
                                        <p>Date applied </p>
                                        <p>Amount Requested</p>
                                        <p>Current Monthly Salary</p>
                                        <p>Current Level</p>
                                        <p>Company</p>
                                        <p>Repayment Option</p>
                                        <p>Repayment Option Status</p>
                                        <p>Account Name</p>
                                        <p>Account Number</p>
                                        <p>Bank Name</p>

                                        {loanRequest &&
                                            loanRequest.repayment_option ===
                                                REPAYMENT_OPTIONS.BANK && (
                                                <>
                                                    <p>
                                                        Mandate Account Number
                                                    </p>
                                                    <p>Mandate Account Name</p>
                                                    <p>Mandate Bank Name</p>
                                                </>
                                            )}
                                    </div>
                                    <div className="loan-request-details-right z-ml-8">
                                        {loanRequest && (
                                            <>
                                                <p>{user && user.firstname}</p>
                                                <p>
                                                    {user && user.lastname}
                                                </p>{" "}
                                                <p>{user && user.email}</p>
                                                <p>
                                                    {moment(
                                                        loanRequest.created_at
                                                    ).format("Do MMMM YYYY")}
                                                </p>
                                                <p>
                                                    {naira}{" "}
                                                    {loanRequestAmount &&
                                                        formatCurrency(
                                                            loanRequestAmount
                                                        )}
                                                </p>
                                                <p>
                                                    {naira}{" "}
                                                    {formatCurrency(
                                                        loanRequest.current_salary
                                                    )}
                                                </p>
                                                <p>
                                                    {loanRequest.company_level &&
                                                        loanRequest
                                                            .company_level.name}
                                                </p>
                                                <p>{company && company.name}</p>
                                                <p>
                                                    {
                                                        loanRequest.repayment_option
                                                    }
                                                </p>
                                                <p>{repaymentOptionStatus}</p>
                                                <p>
                                                    {loanRequest.account_number}
                                                </p>
                                                <p>
                                                    {loanRequest.account_name}
                                                </p>
                                                <p>
                                                    {getBank(
                                                        loanRequest.bank_name
                                                    )}
                                                </p>
                                                {loanRequest &&
                                                    loanRequest.repayment_option ===
                                                        REPAYMENT_OPTIONS.BANK && (
                                                        <>
                                                            <p>
                                                                {mandate
                                                                    ? mandate.account_number
                                                                    : "N/A"}
                                                            </p>
                                                            <p>
                                                                {mandate
                                                                    ? mandate.account_name
                                                                    : "N/A"}
                                                            </p>
                                                            <p>
                                                                {mandate
                                                                    ? getBank(
                                                                          mandate.bank_code
                                                                      )
                                                                    : "N/A"}
                                                            </p>
                                                        </>
                                                    )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {tab === "user_bvn" && (
                            <div className="z-width-100 z-ml-2 z-mt-8">
                                <div className="z-flex-start">
                                    {identityVerification ? (
                                        <>
                                            <div className="loan-request-details-left">
                                                <p>BVN</p>
                                                <p>First Name</p>
                                                <p>Last Name</p>
                                                <p>Middle Name</p>
                                                <p>Date of Birth</p>
                                                <p>Gender</p>
                                            </div>
                                            <div className="loan-request-details-right z-ml-8">
                                                <p>
                                                    {identityVerification.bvn}
                                                </p>
                                                <p>
                                                    {identityVerification.first_name ||
                                                        "N/A"}
                                                </p>
                                                <p>
                                                    {identityVerification.last_name ||
                                                        "N/A"}
                                                </p>
                                                <p>
                                                    {identityVerification.middle_name ||
                                                        "N/A"}
                                                </p>
                                                <p>
                                                    {
                                                        identityVerification.date_of_birth
                                                    }
                                                </p>
                                                <p>
                                                    {
                                                        identityVerification.gender
                                                    }
                                                </p>
                                            </div>{" "}
                                        </>
                                    ) : (
                                        "BVN Not Verified"
                                    )}
                                </div>
                            </div>
                        )}

                        {tab === "guarantor" ? (
                            requestGuarantor ? (
                                <div className="z-width-100 z-ml-2 z-mt-8">
                                    <div className="z-flex-start">
                                        <div className="loan-request-details-left">
                                            <p>First Name</p>
                                            <p>Last Name</p>
                                            <p>Email </p>
                                            <p>Monthly Salary</p>
                                            <p>Account Name</p>
                                            <p>Account Number</p>
                                            <p>Bank Name</p>
                                            <p>Approved Date</p>
                                            <p>Status</p>
                                        </div>
                                        <div className="loan-request-details-right z-ml-8">
                                            {requestGuarantorUser ? (
                                                <>
                                                    <p>
                                                        {
                                                            requestGuarantorUser.firstname
                                                        }
                                                    </p>
                                                    <p>
                                                        {
                                                            requestGuarantorUser.lastname
                                                        }
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {
                                                            requestGuarantor.guarantor_firstname
                                                        }
                                                    </p>
                                                    <p>
                                                        {
                                                            requestGuarantor.guarantor_lastname
                                                        }
                                                    </p>
                                                </>
                                            )}
                                            <p>
                                                {
                                                    requestGuarantor.guarantor_email
                                                }
                                            </p>
                                            <p>
                                                {naira}{" "}
                                                {formatCurrency(
                                                    requestGuarantor.guarantor_salary
                                                )}
                                            </p>
                                            <p>
                                                {requestGuarantor.account_name ||
                                                    requestGuarantor.guarantor_account_name ||
                                                    "N/A"}
                                            </p>
                                            <p>
                                                {requestGuarantor.account_number ||
                                                    requestGuarantor.guarantor_account_number ||
                                                    "N/A"}
                                            </p>
                                            <p>
                                                {getBank(
                                                    requestGuarantor.bank_name
                                                ) ||
                                                    getBank(
                                                        requestGuarantor.guarantor_bank_name
                                                    ) ||
                                                    null}
                                            </p>
                                            <p>
                                                {requestGuarantor.guarantor_approved ===
                                                "pending"
                                                    ? "Pending Approval"
                                                    : requestGuarantor.guarantor_approved &&
                                                      moment(
                                                          requestGuarantor
                                                              .guarantor_approved
                                                              .updated_at
                                                      ).format("Do MMMM YYYY")}
                                            </p>
                                            <p>
                                                {
                                                    requestGuarantor.guarantor_approved
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                "No guarantor details found"
                            )
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </Row>
        </>
    );
}
