import React, { Component, Fragment } from "react";
import LandingPage from "./LandingPage";
import Footer from "./Footer";
import { message, Skeleton } from "antd";
import WelcomeMessageModal from "../modals/WelcomeMessageModal";
import SignupModal from "../modals/SignupModal";
import AOS from "aos";
import "aos/dist/aos.css";
import {
    acceptGuestGuarantorRequest,
    declineGuestGuarantorRequest,
    getCompanies,
    registerUser,
    resendEmailVerificationLink,
    resetPasswordLink,
    setAuthorisationToken,
    userLogin,
} from "../../APIsHandler/ApiController";
import PasswordResetModal from "../modals/PasswordResetModal";
import EmailVerificationModal from "../modals/EmailVerificationModal";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

AOS.init();

export default class HomePage extends Component {
    static queryString;

    constructor(props, queryString) {
        super(props, queryString);

        this.queryString = new URLSearchParams(window.location.search);

        this.state = {
            welcomeModal: false,
            dualModal: false,
            tabNum: 0,
            tabType: 0,
            pageType: 0,
            email: "",
            password: "",
            loginLoading: false,
            companyLoading: false,
            companies: [],
            company: "",
            companyName: null,
            userpassword: "",
            useremail: "",
            firstname: "",
            lastname: "",
            register: false,
            resetLoading: false,
            resetPassword: false,
            isGuarantor: false,
            resendEmailVerification: false,
            redirect: false,
            referralCode: "",
            guarantorRequestLoading: false,
        };
    }

    componentDidMount() {
        if (
            localStorage.getItem("sfs_tk") &&
            localStorage.getItem("sfs_tk") !== undefined &&
            localStorage.getItem("sfs_user") &&
            localStorage.getItem("sfs_user") !== undefined
        ) {
            this.setState({ redirect: true });
        }
        const redirect = this.queryString.get("redirect");

        if (redirect) {
            this.setState({
                resetPassword: false,
                resendEmailVerification: false,
                welcomeModal: false,
                tabNum: 0,
                tabType: 1,
                dualModal: true,
            });
        }

        this.handleGuarantorAction();
        this.handleReferalCode();

        // get all companies
        this.getAllCompanies();
    }

    handleReferalCode = () => {
        const code = this.queryString.get("rel");
        console.log(code);

        if (!code) return;

        this.setState({
            referralCode: code,
            tabNum: 0, // company and referral code tab
            tabType: 0, // sign up modal
            dualModal: true,
        });
    };

    handleGuarantorAction = () => {
        const guarantor = this.queryString.get("us");
        const email = this.queryString.get("email");
        const companyId = this.queryString.get("company_id");
        const companyName = this.queryString.get("company_name");
        const hasAccount = this.queryString.get("has_account"); // 1 = true and 0 = false
        const action = this.queryString.get("action"); // approve | anonymous_approve | disapprove
        const token = this.queryString.get("token");
        const requestGuarantorId = this.queryString.get("request_guarantor_id");
        const loanRequestId = this.queryString.get("loan_request_id");

        if (!guarantor && guarantor !== "gr") return;

        switch (action) {
            case "approve":
                // If guarantor accept the loan request
                this.handleApproveLoan(
                    hasAccount,
                    companyId,
                    companyName,
                    email
                );
                break;

            case "anonymous_approve":
                this.setState({
                    guarantorRequestLoading: true,
                });

                acceptGuestGuarantorRequest(
                    {
                        loan_request_id: loanRequestId,
                        token,
                    },
                    this.onAccept
                );
                break;

            default:
                this.setState({
                    guarantorRequestLoading: true,
                });

                // If loan request was declined/disapproved
                declineGuestGuarantorRequest(
                    {
                        loan_request_id: loanRequestId,
                        email,
                    },
                    this.onDecline
                );
                break;
        }
    };

    handleApproveLoan = (hasAccount, companyId, companyName, email) => {
        if (hasAccount === "0")
            // Gaurantor does not have an account
            return this.setState({
                useremail: email,
                email,
                isGuarantor: true,
                resetPassword: false,
                resendEmailVerification: false,
                welcomeModal: false,
                tabNum: 1, // name and password tab
                tabType: 0, // sign up modal
                dualModal: true,
                company: companyId,
                companyName: companyName,
            });

        // Gaurantor does have an account
        // set a default email
        this.setState({
            useremail: email,
            email,
            isGuarantor: true,
            resetPassword: false,
            resendEmailVerification: false,
            welcomeModal: false,
            tabNum: 0,
            tabType: 1, // login modal
            dualModal: true,
        });
    };

    /**
     * To display success or error message when guarantor disapprove's a loan
     */
    onDecline = (status, data) => {
        this.setState({
            guarantorRequestLoading: false,
        });

        if (status) return message.success("The request has been declined!");

        message.error("Unable to decline request: " + data.data.message);
    };

    onAccept = (status, data) => {
        this.setState({
            guarantorRequestLoading: false,
        });

        if (status) return message.success("The request has been accepted!");

        message.error("Unable to accept request: " + data.data.message);
    };

    //to display welcome modal
    showWelcomeModal = (e, type) => {
        this.setState({
            resetPassword: false,
            resendEmailVerification: false,
            dualModal: false,
            pageType: type,
            welcomeModal: true,
        });
    };

    //to close welcome modal
    closeWelcomeModal = () => {
        this.setState({ welcomeModal: false });
    };

    //to close password modal
    closePasswordModal = () => {
        this.setState({ resetPassword: false });
    };

    //to show password modal
    showPasswordModal = () => {
        this.setState({
            pageType: 0,
            dualModal: false,
            welcomeModal: false,
            resetPassword: true,
            resendEmailVerification: false,
        });
    };

    //to close password modal
    closeEmailVerificationModal = () => {
        this.setState({ resendEmailVerification: false });
    };

    //to show password modal
    showEmailVerificationModal = () => {
        this.setState({
            pageType: 0,
            dualModal: false,
            welcomeModal: false,
            resetPassword: false,
            resendEmailVerification: true,
        });
    };

    //to close signup and login modal
    closeSignupModal = () => {
        this.setState({ dualModal: false });
    };

    //to display signup and login modal / switches login and signup pages
    showSignupModal = (e, tab) => {
        this.setState({
            resetPassword: false,
            resendEmailVerification: false,
            welcomeModal: false,
            tabNum: 0,
            tabType: tab,
            dualModal: true,
        });
    };

    //Go back to signup modal
    goToSignupModal = () => {
        this.setState({ pageType: 0, welcomeModal: true, dualModal: false });
    };

    //function to add organization
    selectOrganization = () => {
        this.setState({ tabNum: 1 });
    };

    //function to navigate back on signup page
    navigateBack = () => {
        const { tabNum } = this.state;
        this.setState({ tabNum: Number(tabNum) - 1 });
    };

    //switches login and signup pages
    switchPage = (e, tab) => {
        this.setState({ tabType: tab });
    };

    //log user in
    logIn = () => {
        const { email, password } = this.state;
        this.setState({ loginLoading: true });
        let formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);

        userLogin(formData)
            .then((res) => {
                const { data, access_token } = res.data;
                //save token to local storage
                localStorage.setItem("sfs_tk", access_token);
                //add token to request header
                setAuthorisationToken(access_token);
                //dispatch the auth user action
                localStorage.setItem("sfs_user", JSON.stringify(data));
                this.setState({ loginLoading: false });
                if (
                    typeof data !== "undefined" &&
                    typeof data === "object" &&
                    data.roles[0].name === "user"
                ) {
                    window.location.href = "/dashboard";
                } else {
                    window.location.href = "/dashboard/admin";
                }
            })
            .catch((err) => {
                this.setState({ loginLoading: false });
                console.log("data", err !== undefined);
                message.error(
                    `Unable to log in.  ${
                        err !== undefined ? err.data.message : ""
                    }`,
                    3
                );
            });
    };

    /*
     * log user in when enter key is pressed*/
    loginEnter = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.logIn();
        }
    };

    //finish sign up
    signIn = (e, tab) => {
        window.location.href = "/";
    };

    //get all companies
    getAllCompanies = () => {
        this.setState({ companyLoading: true });
        getCompanies()
            .then((res) => {
                let { data } = res.data;
                const filteredData = data.filter(
                    (company) => company.status === "approved"
                );

                this.setState({
                    companies: filteredData,
                    companyLoading: false,
                });
            })
            .catch((err) => {
                console.log(err);
                this.setState({ companyLoading: false });
                message.error("Unable to retrieve companies at the moment.", 3);
            });
    };

    // handle input change
    handleForm = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    // handle company input
    handleChange = (value) => {
        this.setState({ company: value });
    };
    /**
     * validate password
     */
    validatePassword = (password) => {
        let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
        return re.test(password);
    };

    /*
     * Register user*/

    registerUser = () => {
        const {
            useremail,
            firstname,
            lastname,
            userpassword,
            company,
            referralCode,
        } = this.state;
        if (!this.validatePassword(userpassword)) {
            message.error(
                "The password must have at least 8 character, which must contain at least one numeric digit, one uppercase and one lowercase letter ",
                3
            );
        } else {
            this.setState({ register: true });
            const params = {
                firstname: firstname,
                lastname: lastname,
                email: useremail,
                password: userpassword,
                company_id: company,
                referral_code: referralCode,
                callback_url: `${window.location.origin}`,
            };
            registerUser(params)
                .then((res) => {
                    this.setState({
                        email: useremail,
                        password: userpassword,
                        dualModal: false,
                        pageType: 1,
                        welcomeModal: true,
                        register: false,
                    });
                })
                .catch((err) => {
                    this.setState({ register: false });
                    console.log(err);
                    message.error(
                        `Unable to register user.  ${
                            err.data !== undefined ? err.data.message : ""
                        }. Please try again.`,
                        3
                    );
                });
        }
    };

    /**
     * Function to resend email verificaiton token
     */

    resendEmailVerification = () => {
        const { email } = this.state;
        console.log(email);
        if (!email) {
            toast.error("Please input an email!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            this.setState({ resetLoading: true });
            let params = {
                email: email,
                callback_url: `${window.location.origin}`,
            };
            resendEmailVerificationLink(params)
                .then((res) => {
                    let { data, message } = res.data;
                    this.setState({
                        resetLoading: false,
                        resendEmailVerification: false,
                    });
                    toast.success(
                        `${
                            message !== undefined
                                ? message
                                : "A verification link has been sent to your email."
                        }`,
                        {
                            position: toast.POSITION.TOP_RIGHT,
                        }
                    );
                })
                .catch((err) => {
                    message.error(
                        `Unable to generate email verification link.  ${
                            err.data !== undefined ? err.data.message : ""
                        }. Please try again.`,
                        3
                    );
                    this.setState({ resetLoading: false });
                });
        }
    };

    /*
     *
     * function to send password rest link
     * */
    resetPassword = () => {
        const { email } = this.state;
        this.setState({ resetLoading: true });
        let params = {
            email: email,
            callback_url: `${window.location.origin}`,
        };
        resetPasswordLink(params)
            .then((res) => {
                this.setState({ resetLoading: false, resetPassword: false });
                message.success("A reset link has been sent to your email.", 3);
            })
            .catch((err) => {
                message.error(
                    `Unable to generate reset link.  ${
                        err.data !== undefined ? err.data.message : ""
                    }. Please try again.`,
                    3
                );
                this.setState({ resetLoading: false });
            });
    };

    render() {
        const { redirect } = this.state;

        if (redirect) {
            return <Redirect to="/dashboard" />;
        }
        return (
            <Skeleton active loading={this.state.guarantorRequestLoading}>
                <LandingPage
                    showWelcomeModal={this.showWelcomeModal}
                    showSignupModal={this.showSignupModal}
                />
                <Footer />
                <WelcomeMessageModal
                    state={this.state}
                    closeWelcomeModal={this.closeWelcomeModal}
                    showSignupModal={this.showSignupModal}
                    signIn={this.signIn}
                />
                <SignupModal
                    state={this.state}
                    closeSignupModal={this.closeSignupModal}
                    selectOrganization={this.selectOrganization}
                    navigateBack={this.navigateBack}
                    goToSignupModal={this.goToSignupModal}
                    showSignupModal={this.showSignupModal}
                    switchPage={this.switchPage}
                    showWelcomeModal={this.showWelcomeModal}
                    logIn={this.logIn}
                    handleForm={this.handleForm}
                    handleChange={this.handleChange}
                    registerUser={this.registerUser}
                    showPasswordModal={this.showPasswordModal}
                    showEmailVerificationModal={this.showEmailVerificationModal}
                    loginEnter={this.loginEnter}
                />
                <PasswordResetModal
                    state={this.state}
                    closePasswordModal={this.closePasswordModal}
                    resetPassword={this.resetPassword}
                    showWelcomeModal={this.showWelcomeModal}
                    handleForm={this.handleForm}
                />

                <EmailVerificationModal
                    state={this.state}
                    resendEmailVerification={this.resendEmailVerification}
                    closeEmailVerificationModal={
                        this.closeEmailVerificationModal
                    }
                    showWelcomeModal={this.showWelcomeModal}
                    handleForm={this.handleForm}
                />
            </Skeleton>
        );
    }
}
