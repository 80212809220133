import React, { useState, useEffect } from "react";
import { message, Skeleton, Button, Popconfirm } from "antd";
import { useHistory } from "react-router-dom";
import {
  getDirectDebitBanks,
  getMandates,
} from "../../../../APIsHandler/ApiController";
import Mandate from "./Mandate";
import NewMandate from "./NewMandate";

export default function Bank({ hideProceedBtn = false }) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [mandates, setMandates] = useState([]);
  const [mandateId, setMandateId] = useState(null);
  const [showCreateMandate, setShowCreateMandate] = useState(false);

  useEffect(() => {
    setLoading(true);
    getMandates()
      .then(({ data }) => {
        setLoading(false);
        setMandates(data.data);
        if (data.data.length > 0) {
          setMandateId(data.data[0].id);
        } else {
          setShowCreateMandate(true);
        }
      })
      .catch(() => {
        setLoading(false);
        message.error("Unable to retrieve accounts at the moment.", 3);
      });

    getDirectDebitBanks()
      .then(({ data }) => {
        setBanks(data.data);
      })
      .catch((err) => {
        message.error("Unable to retrieve banks at the moment.", 3);
      });
  }, []);

  function handleProceed() {
    const param = new URLSearchParams({
      repayment_option: "bank",
      id: mandateId,
    });

    history.push(`/dashboard/apply/?${param.toString()}`);
  }

  return (
    <Skeleton active loading={loading}>
      {!showCreateMandate ? (
        <>
          <Mandate
            banks={banks}
            mandates={mandates}
            mandateId={mandateId}
            setMandateId={setMandateId}
          />
          {mandates.length > 0 && (
            <div
              className="z-flex-center"
              onClick={() => setShowCreateMandate(true)}
            >
              <p className="z-apply-label-small z-no-mb z-modal-parag-small">
                Add new bank
              </p>
            </div>
          )}
          {!hideProceedBtn && (
            <div className="card-btn-group">
              <Popconfirm
                title="Do you want to continue with this bank?"
                onConfirm={handleProceed}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  className="z-landing-btn z-section-btn z-profile-button z-loan-btn"
                  shape="round"
                  size="large"
                  key="1"
                >
                  Proceed{" "}
                </Button>
              </Popconfirm>
            </div>
          )}
        </>
      ) : (
        <NewMandate banks={banks} hideProceedBtn={hideProceedBtn} />
      )}
    </Skeleton>
  );
}
