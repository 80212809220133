import React, { useState, useEffect } from "react";
import { Row, Col, Select, Skeleton, Input, Spin, Button, message } from "antd";
import {
  createMandate,
  getDirectDebitNameEnquiry,
} from "../../../../APIsHandler/ApiController";
import AuthorizationInformation from "./AuthorizationInformation";

const { Option } = Select;

export default function NewMandate({ banks, hideProceedBtn }) {
  const [bankCode, setBankCode] = useState("");
  const [bankNipCode, setBankNipCode] = useState(null);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNameLoading, setAccountNameLoading] = useState(false);
  const [createDirectDebitLoading, setCreateDirectDebitLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [createDirectDebitResponse, setCreateDirectDebitResponse] = useState(null);

  useEffect(() => {
    // handles account name
    if (!bankCode || accountNumber.length < 10) return;

    setAccountNameLoading(true);

    getDirectDebitNameEnquiry({
      account_number: accountNumber,
      bank_code: bankNipCode,
    })
      .then(({ data }) => {
        setAccountNameLoading(false);
        setAccountName(data.data.accountName);
      })
      .catch(() => {
        setAccountNameLoading(false);
        message.error("Unable to retrieve account name at the moment.", 3);
      });
  }, [accountNumber, bankCode, bankNipCode]);

  function handleChangeBank(value) {
    setBankCode(value);

    const bank = banks.find((bank) => bank.cbnBankCode === value);
    if (bank) {
      setBankName(bank.bankName);
      setBankNipCode(bank.nipCode);
    }
  }

  function handleCreateDirectDebit() {
    setCreateDirectDebitLoading(true);
    const payload = {
      account_number: accountNumber,
      bank_code: bankCode,
      account_name: accountName,
      bank_name: bankName,
    };

    createMandate(payload)
      .then(({ data }) => {
        openModal();
        setCreateDirectDebitLoading(false);
        setCreateDirectDebitResponse(data.data);
        message.success("Mandate created successfully.", 3);
      })
      .catch((err) => {
        setCreateDirectDebitLoading(false);
        message.error("Unable to create Mandate at the moment.", 3);
      });
  }

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  function isProceedButtonDisabled() {
    if (
      accountNumber.length >= 10 &&
      bankCode.length > 0 &&
      accountName.length > 0
    ) {
      return false;
    }

    return true;
  }

  return (
    <Spin spinning={createDirectDebitLoading} size="large">
      <Row>
        <Col span={12} className="z-width-44 z-mr-6">
          <p className="z-apply-label-small">Select Bank</p>
          <Select
            className="z-apply-select"
            showSearch
            style={{ width: "100%" }}
            optionFilterProp="children"
            placeholder="Select a bank"
            onChange={handleChangeBank}
            value={bankCode}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {banks &&
              banks.map((bank) => (
                <Option value={bank.cbnBankCode} key={bank.cbnBankCode}>
                  {bank.bankName}
                </Option>
              ))}
          </Select>
        </Col>
        <Col span={12} className="z-width-44">
          <p className="z-apply-label-small">Account Number</p>
          <div>
            <Input
              size="large"
              placeholder="Account Number"
              name="acountNumber"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              className="z-footer-input z-apply-input z-apply-input-small z-lowercase"
            />
          </div>
        </Col>
      </Row>
      <Row className="z-mt-7">
        <Col span={12} className="z-width-44">
          <p className="z-apply-label-small">Account Name</p>
          <Skeleton loading={accountNameLoading} active>
            <div>
              <Input
                size="large"
                placeholder="Account Name"
                name="accountName"
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
                className="z-footer-input z-apply-input z-apply-input-small"
                disabled={accountName.length > 2}
              />
            </div>
          </Skeleton>
        </Col>
      </Row>
      <Row className="z-mt-15_">
        <Col span={24} className="z-flex-center">
          <Button
            className="z-landing-btn z-section-btn z-profile-button z-loan-btn"
            shape="round"
            size="large"
            onClick={handleCreateDirectDebit}
            disabled={isProceedButtonDisabled()}
          >
            Proceed
          </Button>
        </Col>
      </Row>
      <AuthorizationInformation
        showModal={showModal}
        closeModal={closeModal}
        createDirectDebitResponse={createDirectDebitResponse}
        hideProceedBtn={hideProceedBtn}
      />
    </Spin>
  );
}
