import React from "react";
import { Row, Col, Radio } from "antd";

export default function Mandate({ mandates, mandateId, setMandateId }) {
  return (
    <Row>
      <Col span={24}>
        <Radio.Group
          value={mandateId}
          className="z-mandate-radio-group"
          onChange={(e) => setMandateId(e.target.value)}
        >
          {mandates.map(
            ({ id, account_name, account_number, bank_name, status }) => {
              return (
                <div key={id}>
                  <Radio.Button
                    className="z-mandate-radio-button"
                    value={id}
                  >
                    <div className="z-manadate-container">
                      <span>
                        <b>{account_number}</b>
                        <p>{account_name}</p>
                        <p className="z-mandate-bank-name">{bank_name}</p>
                      </span>
                      <p
                        className={`z-manadate-status ${
                          status === "approved" ? "active" : ""
                        }`}
                      >
                        {status === "approved" ? "LINKED" : "NOT LINKED"}
                      </p>
                    </div>
                  </Radio.Button>
                  <br />
                </div>
              );
            }
          )}
        </Radio.Group>
      </Col>
    </Row>
  );
}
