import { useState, useEffect } from "react";
import { getDirectDebitBanks } from "../APIsHandler/ApiController";

export const useBanks = () => {
    const [banks, setBanks] = useState([]);

    useEffect(() => {
        getDirectDebitBanks().then((res) => {
            setBanks(res.data.data);
        });
    }, []);

    const getBank = (bankCode) => {
        if (!bankCode) {
            return "N/A";
        }

        const bank = banks.find(
            (bank) => bank.nipCode == bankCode || bank.code == bankCode
        );

        if (!bank) {
            return "N/A";
        }

        return bank.bankName;
    };

    return { banks, getBank };
};
