import React, { useEffect, useState } from "react";
import { Icon, Layout, message } from "antd";
import DashboardHome from "./userDashboard/DashboardHome";
import UserProfile from "./userDashboard/UserProfile";
import Notifications from "./userDashboard/Notifications";
import UserApplication from "./userDashboard/UserApplication";
import GuarantorRequests from "./userDashboard/GuarantorRequests";
import UserLoans from "./userDashboard/UserLoans";
import AdminHome from "./adminDashboard/AdminHome";
import LoanRequest from "./adminDashboard/LoanRequest";
import CompanyForm from "./adminDashboard/CompanyForm";
import CustomersTable from "./adminDashboard/CustomersTable";
import LoanPackages from "./adminDashboard/LoanPackages";
import { connect, useSelector } from "react-redux";
import {
    deleteNotification,
    getAuthUser,
    getUnreadNotification,
    markAllNotification,
    UserlogOut,
} from "../../APIsHandler/ApiController";
import { AdminNav } from "../globalComponents/AdminNav";
import UserNav from "../globalComponents/UserNav";
import { LogOutModal } from "../globalComponents/LogOutModal";
import { HeaderNav } from "../globalComponents/HeaderNav";
import NavDrawer from "../globalComponents/NavDrawer";
import UserWallet from "./userDashboard/UserWallet";
import TransactionTable from "./adminDashboard/TransactionTable";
import SystemTransaction from "./adminDashboard/SystemTransaction";
import UserTransaction from "./adminDashboard/UserTransaction";
import LoanSchedules from "./adminDashboard/LoanSchedules";
import Blacklist from "./adminDashboard/Blacklist";
import { toast } from "react-toastify";
import { Switch, Route } from "react-router-dom";
import CompanyLevel from "./adminDashboard/CompanyLevel";
import Companies from "./adminDashboard/Companies";
import Administrator from "./adminDashboard/Administrator";
import Referral from "./userDashboard/Referral";
import AdminSetting from "./adminDashboard/AdminSetting";
import Repayment from "./userDashboard/Repayment";
import HandleCardAuthorization from "./userDashboard/HandleCardAuthorization";
import PaymentAuthorizations from "./userDashboard/PaymentAuthorizations";
import LoanRequestDetails from "./adminDashboard/LoanRequestDetails";
import DirectDebitTransaction from "./adminDashboard/DirectDebitTransaction";

const { Content } = Layout;

const DashboardLayout = (props) => {
    const user = useSelector((state) => state.user);
    const [todayDate, setTodayDate] = useState(new Date());
    const [currentNav, setCurrentNav] = useState(
        localStorage.hasOwnProperty("defaultSelectedNav") &&
            localStorage.getItem("defaultSelectedNav") !== "undefined"
            ? localStorage.getItem("defaultSelectedNav")
            : "1"
    );
    const [currentSub, setCurrentSub] = useState(
        localStorage.hasOwnProperty("defaultSelectedSub") &&
            localStorage.getItem("defaultSelectedSub") !== "undefined"
            ? localStorage.getItem("defaultSelectedSub")
            : "1"
    );
    const [logOut, setLogOut] = useState(false);
    const [userRole, setUserRole] = useState("user");
    const [userInfo, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [sideBarCollapsed, setSideBarCollapsed] = useState(false);
    const [avatar, setAvatar] = useState("");
    const [showDrp, setShowDrp] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [removeNote, setRemoveNote] = useState([]);
    const [noteId, setNoteId] = useState("");
    const [badge, setBadge] = useState(0);
    const [showFull, setShowFull] = useState(false);

    useEffect(() => {
        let startComponent = false;

        if (!startComponent) {
            console.log(
                "Selected sub",
                typeof localStorage.getItem("defaultSelectedNav"),
                localStorage.getItem("defaultSelectedNav")
            );
            if (window.innerWidth <= 1000) {
                setSideBarCollapsed(true); //auto collapse sidebar on election list page
            }
            if (
                typeof props.user !== "undefined" &&
                typeof props.user.data === "object" &&
                props.user.data.roles[0].name === "user"
            ) {
                props.getAuthUser().then(() => {
                    let avatar =
                        props.user.data.hasOwnProperty("user_profile") &&
                        props.user.data.user_profile !== null &&
                        props.user.data.user_profile.avatar !== null
                            ? props.user.data.user_profile.avatar
                            : localStorage.hasOwnProperty(
                                  "sfs_avatar" + props.user.data.id
                              )
                            ? JSON.parse(
                                  localStorage.getItem(
                                      "sfs_avatar" + props.user.data.id
                                  )
                              )
                            : "";
                    setUser(props.user);
                    setAvatar(avatar);
                    setLoading(false);
                });
                getUnreadNotification()
                    .then((res) => {
                        const { data } = res.data;
                        setNotifications(data);
                        setBadge(data.length);
                    })
                    .catch((err) => {
                        message.error("Unable to retrieve notifications");
                    });
            } else {
                props.getAuthUser().then(() => {
                    console.log("sds");
                    setUserRole("admin");
                    setLoading(false);
                });
            }
        }

        return () => {
            startComponent = true;
        };
    }, []);

    //set nav item index in localstorage
    const onNavClick = (e, i, j) => {
        console.log("inside life", i, j);
        // if((i !== 'undefined' && j !== 'undefined') || (i !== undefined && j !== undefined)) {
        localStorage.setItem("defaultSelectedNav", JSON.stringify(i));
        localStorage.setItem("defaultSelectedSub", JSON.stringify(j));
        setCurrentNav(i);
        setCurrentSub(j);
        setDrawer(false);
        if (window.innerWidth <= 1000) {
            setSideBarCollapsed(true); //auto collapse sidebar on  list page
        }
        // }
    };

    //logs user out
    const userLogOut = (e) => {
        UserlogOut()
            .then((res) => {
                localStorage.removeItem("sfs_tk");
                localStorage.removeItem("sfs_user");
                window.location.href = "/";
                toast.success("Logged out successfully");
            })
            .catch((err) => {
                toast.error("Error logging user out");
            });
    };

    //logs user out
    const gotoProfile = (e) => {
        window.location.href = "/dashboard/settings";
        localStorage.setItem("defaultSelectedNav", JSON.stringify("6"));
    };

    const showDetails = () => {
        setShowDrp((showDrp) => !showDrp);
    };

    const closeDetails = () => {
        setShowDrp((showDrp) => !showDrp);
    };

    const showSideBar = () => {
        console.log("Show Drawer", drawer);
        setSideBarCollapsed((sideBarCollapsed) => !sideBarCollapsed);
        setDrawer((drawer) => !drawer);
        // setShowFull(showFull => !showFull);
    };
    const showDrawer = () => {
        console.log("Show Drawer", drawer);
        setDrawer((drawer) => !drawer);
    };

    /*make notification as read*/
    const deleteNote = (id) => {
        removeNote.push(id);
        setRemoveNote([...removeNote]);
        setNoteId(id);
        this.setState({ removeNote, noteId: id });
        deleteNotification({ notificationId: id })
            .then((res) => {
                const { notifications, removeNote, noteId } = this.state;
                removeNote.splice(removeNote.indexOf(noteId, 1));
                const newNotification = notifications.filter(
                    (notification) => notification.id !== noteId
                );
                this.setState({ removeNote, notifications: newNotification });
            })
            .catch((err) => {
                const { removeNote, noteId } = this.state;
                removeNote.splice(removeNote.indexOf(noteId, 1));
                this.setState({ removeNote });
                message.error("Unable to delete notification.");
            });
    };

    /*mark notification as read*/
    const markAll = () => {
        markAllNotification().then((res) => {
            setBadge(0);
        });
    };

    const handleLogout = (value) => {
        if (value === "true") {
            setLogOut(true);
        } else {
            setLogOut(false);
        }
    };

    return (
        <div>
            {" "}
            {loading ? (
                <div>
                    <Icon type="loading" />
                </div>
            ) : (
                <div>
                    <Layout className="z-dashboard">
                        {userRole === "admin" ? (
                            <AdminNav
                                {...props}
                                sideBarCollapsed={sideBarCollapsed}
                                currentNav={currentNav}
                                currentSub={currentSub}
                                onNavClick={onNavClick}
                                showlogModal={(e) => setLogOut(true)}
                                showFull={showFull}
                            />
                        ) : (
                            <UserNav
                                {...props}
                                showDrp={showDrp}
                                sideBarCollapsed={sideBarCollapsed}
                                currentNav={currentNav}
                                onNavClick={onNavClick}
                                showlogModal={(e) => setLogOut(true)}
                                showDetails={showDetails}
                                closeDetails={closeDetails}
                                gotoProfile={gotoProfile}
                                showFull={showFull}
                                user={props.user}
                            />
                        )}
                        <Layout>
                            <HeaderNav
                                {...props}
                                badge={badge}
                                user={props.user}
                                showDrawer={showDrawer}
                                showSideBar={showSideBar}
                                notifications={notifications}
                                deleteNotification={deleteNote}
                                userRole={userRole}
                                todayDate={todayDate}
                                deleteNote={removeNote}
                                markAll={markAll}
                            />
                            <Content>
                                <Switch>
                                    <Route
                                        exact
                                        path={"/dashboard"}
                                        component={DashboardHome}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/settings"}
                                        component={UserProfile}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/notifications"}
                                        component={Notifications}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/apply"}
                                        component={UserApplication}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/repayment-options/"}
                                        component={Repayment}
                                    />
                                    <Route
                                        exact
                                        path={
                                            "/dashboard/repayment-options/:defaultCard"
                                        }
                                        component={Repayment}
                                    />
                                    <Route
                                        exact
                                        path={
                                            "/dashboard/payment-authorizations"
                                        }
                                        component={PaymentAuthorizations}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/handle-authorization"}
                                        component={HandleCardAuthorization}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/guarantor"}
                                        component={GuarantorRequests}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/loans"}
                                        component={UserLoans}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/wallet"}
                                        component={UserWallet}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/referral"}
                                        component={Referral}
                                    />

                                    <Route
                                        exact
                                        path={"/dashboard/admin"}
                                        component={AdminHome}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/admin/requests"}
                                        component={LoanRequest}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/admin/requests/:loanRequestId"}
                                        component={LoanRequestDetails}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/admin/companies"}
                                        component={Companies}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/admin/new-company"}
                                        component={CompanyForm}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/admin/customers"}
                                        component={CustomersTable}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/admin/administrators"}
                                        component={Administrator}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/admin/packages"}
                                        component={LoanPackages}
                                    />
                                    <Route
                                        exact
                                        path={
                                            "/dashboard/admin/user-transactions"
                                        }
                                        component={TransactionTable}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/admin/loan-schedules"}
                                        component={LoanSchedules}
                                    />
                                    <Route
                                        exact
                                        path={
                                            "/dashboard/admin/loan-schedules/:loanRequestId"
                                        }
                                        component={LoanSchedules}
                                    />
                                    <Route
                                        exact
                                        path={
                                            "/dashboard/admin/system-transactions"
                                        }
                                        component={SystemTransaction}
                                    />
                                    <Route
                                        exact
                                        path={
                                            "/dashboard/admin/transactions/:id"
                                        }
                                        component={UserTransaction}
                                    />
                                    <Route
                                        exact
                                        path={
                                            "/dashboard/admin/bank-transactions"
                                        }
                                        component={DirectDebitTransaction}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/admin/blacklist"}
                                        component={Blacklist}
                                    />
                                    <Route
                                        exact
                                        path={
                                            "/dashboard/admin/company-level/:companyId"
                                        }
                                        component={CompanyLevel}
                                    />
                                    <Route
                                        exact
                                        path={"/dashboard/admin/settings"}
                                        component={AdminSetting}
                                    />
                                </Switch>
                            </Content>
                        </Layout>
                        <div>
                            <LogOutModal
                                {...props}
                                logOut={logOut}
                                logUserOut={(e) => setLogOut(false)}
                                userLogOut={userLogOut}
                            />
                        </div>
                        {drawer && (
                            <NavDrawer
                                {...props}
                                showDrp={showDrp}
                                userRole={userRole}
                                sideBarCollapsed={sideBarCollapsed}
                                currentNav={currentNav}
                                onNavClick={onNavClick}
                                showlogModal={(e) => setLogOut(true)}
                                user={props.user}
                                gotoProfile={gotoProfile}
                                drawer={drawer}
                                showDetails={showDetails}
                                showDrawer={showDrawer()}
                                closeDetails={closeDetails}
                            />
                        )}
                    </Layout>
                </div>
            )}
        </div>
    );
};

/**
 * function to map the state to props
 * */
function mapStateToProps(state) {
    return {
        user: state.user,
    };
}
export default connect(mapStateToProps, { getAuthUser })(DashboardLayout);
